import React from "react";

const onMouseOverCard = (e,key) => {
    e.currentTarget.parentElement.parentElement.classList.remove("whywecard-custom");
    e.currentTarget.parentElement.parentElement.classList.add("whywecard-over");
}

const onMouseLeaveCard = e => {
    e.currentTarget.parentElement.parentElement.classList.remove("whywecard-over");
    e.currentTarget.parentElement.parentElement.classList.add("whywecard-custom");
}
export const WhyWeCard = ({
    classIcon,
    title, 
    textInfo
}) =>
        <div className="col-lg-3">
            <div className="softwere-about-box text-center p-3">
                <div className="softwere-about-icon" >
                    <i className={classIcon} 
                        onMouseOver={onMouseOverCard} 
                        onMouseLeave={onMouseLeaveCard}
                    />
                </div>
                <h4 className="f-15 text-uppercase letter-spacing_2 mt-4">{title}</h4>
                <p className="mt-4">{textInfo}</p>
            </div>
        </div>;