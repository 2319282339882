import React from 'react';
import CustomCabinet from './CustomCabinet.jsx';
import RubberCabinet from './cabinet/rubber/RubberCabinet.jsx';
import { BrowserRouter,Outlet, Routes, Route } from "react-router-dom";
const RubberCabinetOutlets = () => 
<div>
    <RubberCabinet/>               
    <Outlet />
</div>;
//const objComponent = {custom: <CustomCabinet/>, rubber: <RubberCabinetOutlets/>}
const App = () => 
    <BrowserRouter>
        <Routes >
            <Route path="/" element={<CustomCabinet/>}/>
            <Route path='cabinet/rubber' element={<RubberCabinetOutlets/>}/> 
            <Route component={'NotFoundPage'} />
        </Routes>
     </BrowserRouter>
export default App;