import React from 'react';

const AboutCompany = () => {
    return (
        <section className="section" id="features">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="text-center">
                            <h2 className="heading-title-title letter-spacing_4 text-uppercase">О компании</h2>
                        </div>
                    </div>
                </div>

                <div className="row mt-5 pt-3 align-items-center">
                    <div className="col-lg-6">
                        <div className="softwere-features-img mt-4 text-center">
                            <div className="img-fluid software-about-company-left" alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="softwere-features-content">
                            <p className="softwere-features-desc mt-4 about-company-left">Компания ООО «Телерос» основана в 2021 году. Наша миссия - вывести коммуникации b2p на новый уровень. Каждый день в мире появляются новые решения, создаются новые стандарты, повышаются требования к качеству, вводятся новые системы контроля. Мы создаем инструменты для адаптации к стремительно меняющимся условиям рынка коммуникаций. Оптимизация расходов, увеличение производительности, улучшение качества обслуживания потребителей - наши основные задачи.</p>
                        </div>
                    </div>
                </div>

                <div className="row mt-5 pt-5 align-items-center">
                    <div className="col-lg-6">
                        <div className="softwere-features-content">
                            <p className="softwere-features-desc mt-4">Компания занимается изучением в области применения искусственного интеллекта в области коммуникаций: отработка сценариев диалога, NLP, обработка, классификация и преобразование аудио потоков. В IP телефонии организуем мобильные, статические и динамические карусели. И можем предложить множество решений с участием наших партнеров.</p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="softwere-features-img mt-4 text-center">
                            <div className="img-fluid software-about-company-right" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutCompany;