import React from 'react';
import { validateForm }  from '../js/contact.js';
import { onChangeNumber } from '../js/contact.js';
import { onPasteNumber } from '../js/contact.js';
import { onKeyDownNumber } from '../js/contact.js';
import { E_MAIL_REG } from '../js/contact.js';
const Feedback = () => {
    return (
        <section className="section agency-contact" id="feedback">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="text-center">
                            <h2 className="heading-title-title letter-spacing_4 text-uppercase">Обратная связь</h2>
                        </div>
                    </div>
                </div>

                <div className="row mt-5 pt-3 align-items-center justify-content-center">
                    <div className="col-lg-7">
                        <div className="custom-form mt-4">
                            <form method="post" onSubmit={(form) => {console.log('onsubmit button'); validateForm(form);}} className="contact-form" name="myForm" id="myForm">
                                <div id="error-msg"></div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group my-3">
                                            <input name="name" id="name" type="text" className="form-control" placeholder="Ваше имя"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group my-3">
                                            <input 
                                                   title={'пример электронной почты example@email.ru'}
                                                   pattern={E_MAIL_REG}
                                                   type='email'
                                                   name="email" 
                                                   id="email" 
                                                   className="form-control" 
                                                   placeholder="Ваш Email"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group my-3">
                                            <input 
                                            title={'пример телефона 81112223344'}
                                            pattern='^(\+7|7|8)?[\s\-]?\(?[0-9]{1}[0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$'
                                            name="number"
                                            id="number"
                                            type="tel"
                                            onPaste={onPasteNumber}
                                            onChange={ onChangeNumber }
                                            onKeyDown={onKeyDownNumber}
                                            className="form-control"
                                            placeholder="Ваш номер"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group my-3">
                                            <textarea name="comments" id="comments" rows="5" className="form-control" placeholder="Ваше сообщение"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                <div className="checkbox-data">
                                    <label>
                                        <input className="" type="checkbox" name="person_data" id="person_data" required=""/>
                                        <i className="ms-2 fa fa-2x icon-checkbox"></i>
                                        <span className="mt-4">Подтверждаю согласие на обработку <a href="https://zlb.su/assets/policy_pdn.pdf" target="_blank">персональных данных</a></span>
                                    </label>
                                </div>
                            </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="mt-3 text-center">
                                            <input type="submit" id="submit" name="send" className="submitBnt btn btn-md btn-dark-custom" value="Отправить"/>
                                            <div id="simple-msg"></div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Feedback;