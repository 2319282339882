//
// Contact Form Js
//

export const E_MAIL_REG = "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$";
export const onChangeMail = e => {
    const currentSymbols = e.target.value.slice(-1);
    console.log('onChangeMail_____', e.target.value);
    if(e.target.value.search(E_MAIL_REG) !== -1){
        e.target.value = e.target.value.slice(0, -1);
    }
    console.log('onChangeMail', e.target.value);
}

export const onChangeNumber = e => {
    if(e.target.value){
        e.target.value = e.target.value.replace(/\D/g, '');
    }
}
export const onKeyDownNumber = e =>{
    console.log('charCode',e.keyCode)
    if (e.keyCode && (e.keyCode > 48 && e.keyCode < 57) && e.target.value.length >= 11){
            e.stopPropagation();
            e.preventDefault();
    }
}

export const onPasteNumber = e => {
    let clipboardData = e.clipboardData;
    let pastedData = clipboardData.getData('Text');
    pastedData = pastedData.replace(/[^\d]/g, '');
    if(pastedData.length > 11){
        e.stopPropagation();
        e.preventDefault();
    }else{
        e.target.value =  pastedData
    }


}

export function validateForm(form) {
    form.preventDefault();
    let name = form.target.name.value;
    let email = form.target.email.value;
    let comments = form.target.comments.value;
    let phone = form.target.number.value || "";
    let personData = form.target.person_data.checked;
    document.getElementById("error-msg").style.opacity = 0;
    document.getElementById('error-msg').innerHTML = "";
    if (name == "" || name == null) {
        fadeIn(form, 'Не введено имя*');
        return false;
    }
    if (email == "" || email == null) {
        fadeIn(form, 'Не введен email*');
        return false;
    }
    if (comments == "" || comments == null) {
        fadeIn(form, 'Не введен коментарий*');
        return false;
    }
    if (!personData) {
        fadeIn(form, 'Не отмечено согласие на обработку данных*');
        return false;
    }
    let xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
            fadeInInfo(form, `Спасибо, ${name}, мы свяжемся с Вами в самое ближайшее время!`);
            form.target.name.value = "";
            form.target.email.value = "";
            form.target.comments.value = "";
            form.target.number.value = "";
            form.target.person_data.checked = false;
        }
    };
    xhttp.open("POST", "https://zlb.su/contact/send.php");
    xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhttp.send(`name=${name}&email=${email}&phone=${phone}&text=${comments}&person_data=${personData}&usluga=Детектор+аудио`);

    xhttp.onerror = function() {
        console.log('Ошибка соединения')
      };

    xhttp.onloadend = function() {
        if (xhttp.status == 200) {
            console.log("Успех");
        } else {
            console.log("Ошибка " + this.status);
        }
        fadeInInfoClear(document.getElementById("error-msg"));
    };
    return false;
}

export function clientValidateForm(form) {
    form.preventDefault();
    let name = form.target.name.value;
    let comments = form.target.comments.value;
    console.log('clientForm', name, comments)
    document.getElementById("error-msg").style.opacity = 0;
    document.getElementById('error-msg').innerHTML = "";
    if (name == "" || name == null) {
        fadeIn(form, 'Не введено имя*');
        return false;
    }
    if (comments == "" || comments == null) {
        fadeIn(form, 'Не введен комментарий*');
        return false;
    }
    let xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
            fadeInInfo(form, `Клиент, ${name} добавлен`);
            form.target.name.value = "";
            form.target.comments.value = "";
        }
    };
    xhttp.open("POST", "https://zlb.su/cabinet/rudder/api.php?action=add_client");
    xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhttp.send(`name=${name}&comments=${comments}`);

    xhttp.onerror = function() {
        console.log('Ошибка соединения')
      };

    xhttp.onloadend = function() {
        if (xhttp.status == 200) {
            console.log("Успех");
        } else {
            console.log("Ошибка " + this.status);
        }
        fadeInInfoClear(document.getElementById("error-msg"));
    };
    return false;
}

function fadeIn(form,text) {
    let fade = document.getElementById("error-msg");
    fade.innerHTML = `<div class='alert alert-danger error_message'><i  data-feather='home' class='icon-sm align-middle me-2'></i>${text}</div>`;
    let opacity = 0;
    let intervalID = setInterval(function () {
        if (opacity < 1) {
            opacity = opacity + 0.5
            fade.style.opacity = opacity;
        } else {
            clearInterval(intervalID);
        }
    }, 200);
}

function fadeInInfo(form,text) {
    let fade = document.getElementById("error-msg");
    fade.innerHTML = `<div class='alert alert-success  info_message'><i  data-feather='contact' class='icon-sm align-middle me-2'></i>${text}</div>`;
    let opacity = 0;
    let intervalID = setInterval(function () {
        if (opacity < 1) {
            opacity = opacity + 0.5
            fade.style.opacity = opacity;
        } else {
            clearInterval(intervalID);
        }
    }, 200);
}

function fadeInInfoClear(fade) {
    if(fade){
        setTimeout(() => {
            fade.style.opacity = 0;
        }, 3000);
    }
}

export function getClients (callBack){
    let clients = [];
    let xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
            clients =  this.response;
        }
    };
    xhttp.open("POST", "https://zlb.su/cabinet/rudder/api.php?action=get_clients");
    xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhttp.send();
    xhttp.onerror = function() {
        callBack([]);
        console.log('Ошибка соединения')
      };

    xhttp.onloadend = function() {
        if (xhttp.status == 200) {
            console.log("Успех");
            callBack(this.response);
        } else {
            console.log("Ошибка " + this.status);
            callBack([]);
        }
    };
}