'use_strict';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.jsx';
import '../css/bootstrap.min.css'
import '../css/pe-icon-7-stroke.min.css'
import '../scss/style.scss';

const root = createRoot(document.getElementById('root'));
const element = <App/>;
root.render(element);