import React,{useEffect,useState} from 'react';
import {toggleMenu} from '../js/app.js';
import { NavLink } from 'react-router-dom';
const NavBar = ({navItemsObj}) => {
    const [windowSize, setWindowSize] = useState(document.documentElement.clientWidth);
    useEffect(() => {
        window.onresize = (e) =>{
            setWindowSize(document.documentElement.clientWidth);
        }
	},[])

    return (
            <nav className="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark nav-sticky" id="navbar">
                <div className="container">
                    {/* <!-- LOGO --> */}
                    <a className="navbar-brand logo" href="https://zlb.su">
                        <b>ZLB.SU</b>
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span></button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav ms-auto navbar-center" id="mySidenav">
                            {Object.keys(navItemsObj).map(id =>
                                <li className="nav-item" key={id} onClick={() => toggleMenu(windowSize)}>
                                    { navItemsObj[id].path
                                    ? <NavLink to={ navItemsObj[id].path } className="nav-link me-0">{ navItemsObj[id].title }</NavLink> 
                                    : <a href={id} className="nav-link me-0">{ navItemsObj[id].title }</a>}
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </nav>
    );
}
export default NavBar;