//import bootstrap from '../js/bootstrap.bundle.min.js'
//import {Collapse} from 'bootstrap'
/* Theme Name:  Tulsy- Responsive Landing page template
  File Description: Main JS file of the template
*/

//  Window scroll sticky class add
// function windowScroll() {
//   const navbar = document.getElementById("navbar");
//   if (
//     document.body.scrollTop >= 50 ||
//     document.documentElement.scrollTop >= 50
//   ) {
//     navbar.classList.add("nav-sticky");
//   } else {
//     navbar.classList.remove("nav-sticky");
//   }
// }

// function initAppScroll() {
//   window.addEventListener('scroll', (ev) => {
//     console.log('initAppScroll');
//     ev.preventDefault();
//     windowScroll();
//   })
// }

function initNavLinksToggle(windowSize){
  const navLinks = document.querySelectorAll('.nav-item');
  const menuToggle = document.getElementById('navbarCollapse');
  navLinks.forEach((l) => {
    l.addEventListener('click', () => {
      console.log('initNavLinksToggle__000',windowSize)
        toggleMenu(windowSize); });
  });
}
function toggleMenu(windowSize) {
  let bsCollapse = '';
  let menuToggle = '';
  try{
    if (windowSize < 980) {
      menuToggle = document.getElementById('navbarCollapse');
      bsCollapse = new bootstrap.Collapse(menuToggle, { toggle: true });
      bsCollapse.toggle();
    } else {
      bsCollapse = '';
    }
  }catch(e){
    console.log('error in toggleMenu',e);
  }

}

export {initNavLinksToggle, toggleMenu}