import React, {useState} from 'react';
import Footer from '../../Footer.jsx';
import Client from './Client.jsx';
import Trunk from './Trunk.jsx';
import Route from './Route.jsx';
import Tables from './Tables.jsx';
const navItemsObj = {
    "client": 'Клиенты',
    "trunk": 'Транки',
    "route": 'Маршруты',
    "tables": 'Таблицы',
}

const navComponentsObj = {
    "client": <Client/>,
    "trunk": <Trunk/>,
    "route": <Route/>,
    "tables": <Tables/>,
}
const getTabComponent = (tabId, index) =>{
    let component = null;
    if(tabId === "client"){
        component = <Client tIndex={index}/>
    }
    if(tabId === "trunk"){
        component = <Trunk tIndex={index}/>
    }
    if(tabId === "route"){
        component = <Route tIndex={index}/>
    }
    if(tabId === "tables"){
        component = <Tables tIndex={index}/>
    }
    return component;
}

const navComponentsNumbs = {
    "client": 0,
    "trunk": 1,
    "route": 2,
    "tables": 3,
}
const RubberCabinet = () => {
    const [tabIndex, setTabIndex] = useState(navComponentsNumbs.client); 
    return (
        <div className='d-flex align-items-around-space flex-column bd-highlight rootForRubberCabinet'>
            <nav className='p-2 bd-highlight navForRubberCabinet'>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <a className="navbar-brand logo" style={{display:'flex', justifyContent:'center', alignItems:'center', width:100,height: 50}} href="https://zlb.su">
                        <b>ZLB.SU</b>
                    </a>
                        {Object.keys(navItemsObj).map((id, index) =>
                                    <li className="nav-item" role="presentation"  key={id}>
                                    <button 
                                        onClick={(e) => {
                                            setTabIndex(navComponentsNumbs[e.target['aria-controls']])
                                        }}
                                        className={`nav-link ${index === 0 ? 'active' : ''}`} 
                                        id={`${id}-tab`} 
                                        data-bs-toggle="tab" 
                                        data-bs-target={`#${id}`} 
                                        type="button" 
                                        role="tab" 
                                        aria-controls={`${id}`} 
                                        aria-selected="true">
                                            {`${navItemsObj[id]}`}
                                    </button>
                                </li>
                            )}
                </div>
            </nav>
            <div className="tab-content p-2 bd-highlight" style={{overflow: 'hidden'} }id="nav-tabContent">
            {Object.keys(navComponentsObj).map((id, index) =>
                                <div className={`tab-pane tabPaneForRubberCabinet ${index === 0 ? 'active' : ''}`} id={`${id}`} role="tabpanel" aria-labelledby={`${id}-tab`} key={id}>
                                    {getTabComponent(id, index)}</div>
                            )} 
        </div>
        <div className={"mt-auto p-2 bd-highlight navForRubberCabinet"}>
            <Footer/>
        </div>

        </div>
    )
};
export default RubberCabinet;