import React, {useEffect, useState} from 'react';

import { clientValidateForm, getClients } from '../../../js/contact';

const idTablesObj = {
    id_user:'Id пользователя',
    name:'Имя',
    manager:'Менеджер',
    comments:'Комментарий'
}
// const DEBAG_TABLE = [
//     {id_user:'id_user',name: 'name', manager: 'manager',comments: 'comments comments comments comments comments comments comments comments comments commentscomments'},
//     {id_user:'id_user1',name: 'name1', manager: 'manager1',comments: 'comments1comments1comments1comments1'},
//     {id_user:'id_user2',name: 'name2', manager: 'manager2',comments: 'comments2'},
//     {id_user:'id_user3',name: 'name3', manager: 'manager3',comments: 'comments3'},
//     {id_user:'id_user3',name: 'name3', manager: 'manager3',comments: 'comments3'},
//     {id_user:'id_user3',name: 'name3', manager: 'manager3',comments: 'comments3'},
//     {id_user:'id_user3',name: 'name3', manager: 'manager3',comments: 'comments3'},
//     {id_user:'id_user3',name: 'name3', manager: 'manager3',comments: 'comments3'},
//     {id_user:'id_user3',name: 'name3', manager: 'manager3',comments: 'comments3'},
//     {id_user:'id_user3',name: 'name3', manager: 'manager3',comments: 'comments3'},
//     {id_user:'id_user3',name: 'name3', manager: 'manager3',comments: 'comments3'},
//     {id_user:'id_user3',name: 'name3', manager: 'manager3',comments: 'comments3'},
//     {id_user:'id_user3',name: 'name3', manager: 'manager3',comments: 'comments3'},
//     {id_user:'id_user3',name: 'name3', manager: 'manager3',comments: 'comments3'}
// ];

const Client = ({tIndex}) => {
    const [clients, setClients] = useState([]);
    const [tabIndex, setTabIndex] = useState(tIndex);
    useEffect(() => {
        console.log(' use effects Client')
        if(tabIndex != tIndex){
            setTabIndex(tIndex)
        if(tabIndex === 0)
            getClients((clientsObj) =>{
                let clientsLocal = [];
                try{
                    clientsLocal = clientsObj.response ? clientsObj.clients : [];
                }catch(e){
                    clientsLocal = [];
                    console.log('error in getClients');
                }
                setClients(clientsLocal);
            });
        }
	},[tabIndex])
    return (
            <div className="container rootClient">
               {
                clients && clients.length > 0 && <div className='row justify-content-center rowClient'>
                    <div className='col-11 colClient'
                    >
                        <table className="table">
                            <thead>
                                <tr>
                                {Object.keys(idTablesObj).map(id =>
                                    <th className='thClient' key={`${id}`} scope="col" >{idTablesObj[id]}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {clients.map((row, i) =>
                                            <tr>
                                                {Object.keys(row).map( id => 
                                                        <td style={{wordBreak: 'break-all'}}>{`${row[id]}`}</td>
                                                )}
                                            </tr>
                                        )}
                            </tbody>
                        </table>
                    </div>
                </div>
               }    
                <div className='container flex-lg-nowrap' >
                <div className="row">
                    <form 
                        method="post" 
                        onSubmit={(form) => {console.log('onsubmit button___'); 
                        clientValidateForm(form);}} 
                        className="contact-form" 
                        name="myForm" 
                        id="myForm"
                        >
                        <p><b>Добавить нового пользователя</b></p>
                        <div id="error-msg"></div>
                        <div className='container'>
                            <div className='row'>
                                <div className="col-lg-5">
                                    <div className="form-group my-3">
                                        <input name="name" id="name" type="text" className="form-control" placeholder="Наименование"/>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="form-group my-3">
                                        <input name="comments" id="comments" type="text" className="form-control" placeholder="Комментарии"/>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                <div className="mt-3 text-center">
                                    <input type="submit" id="submit" name="send" className="submitBnt btn btn-md btn-dark-custom" value="добавить"/>
                                    <div id="simple-msg"></div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </form>
                </div>
                </div>
            </div>
    );
}

export default Client;