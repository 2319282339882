import React from 'react';
import NavBar from './NavBar.jsx';
import Home from './Home.jsx';
import AboutProject from './AboutProject.jsx';
import AboutCompany from './AboutCompany.jsx';
import WhyWe from './WhyWe.jsx';
import Pricing from './Pricing.jsx';
import Feedback from './Feedback.jsx';
import Contact from './Contact.jsx';
import Footer from './Footer.jsx';

// import {initAppScroll} from '../js/app.js';
const navItemsObj = {
    '#home':{ title: 'Главная' },
    '#about':{ title: 'О Проекте' },
    '#features':{ title: 'О компании' },
    '#whywe':{ title: 'Почему мы' },
    '#pricing':{ title: 'Цифры' },
    '#feedback':{ title: 'Обратная связь' },
    '#contact':{ title: 'Контакты' },
    "#cabinet": { title: 'Учетные записи' } ,
    "http://localhost:3001/cabinet/rubber/": { title: 'Телефония', path: 'cabinet/rubber' },
    "https://zlb.su:8888": { title: 'Личный кабинет' },
}
const CustomCabinet = () => 
        <div>
            <NavBar navItemsObj = { navItemsObj }/>
            <Home/>
            <hr/>
            <AboutProject/>
            <hr/>
            <AboutCompany/>
            <hr/>
            <WhyWe/>
            <hr/>
            <Pricing/>
            <hr/>
            <Feedback/>
            <hr/>
            <Contact/>
            <Footer/>
        </div>
export default CustomCabinet;