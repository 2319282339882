import React from'react';
export const DialogComponent = ({
    textButton, 
    textButtonClose, 
    textButtonOk, 
    titleDialog, 
    contentDialog,
    idComponent="staticBackdrop",
    idLabel="staticBackdropLabel"}) =>
<React.Fragment>
    <button type="button" className="btn btn-link btn-md-text-16 mt-2" data-bs-toggle="modal" data-bs-target={`#${idComponent}`}>
        {textButton}
    </button>

    <div className="modal fade" id={idComponent} data-bs-keyboard="false" tabIndex="-1" aria-labelledby={idLabel} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 id={idLabel}>{ titleDialog }</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
          </div>
          <div className="modal-body">
            { contentDialog }
          </div> 
          {
            (textButtonClose || textButtonOk ) && <div className="modal-footer">
             { textButtonClose && <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{ textButtonClose }</button> }
             { textButtonOk && <button type="button" className="btn btn-primary">{ textButtonOk }</button> }
            </div>
          }
        </div>
      </div>
    </div>
</React.Fragment>