import React from 'react';

const Footer = () => {
    return (
    <footer className="section-footer bg-softwere-footer CabinetRubberFooter">
        <div className="container">
            <div className="row mt-4 py-4">
                <div className="col-lg-12">
                    <div className="text-center">
                        <p className="copy-rights text-white mb-0">© 2024 Copyright: ZLB.SU | ООО "ТЕЛЕРОС" </p>
                    </div>
                </div>

            </div>
        </div>
    </footer>
    );
}

export default Footer;