import React from 'react';
import { DialogComponent } from './DialogComponent.jsx';
import documentImage from '../images/Свидетельство № 2023612049 Телерос.png';

const AboutProject = () => {
    return (
        <section className="section software-about" id="about">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="text-center">
                            <h2 className="heading-title-title letter-spacing_4 text-uppercase">О проекте</h2>
                            <p className="heading-title-desc mt-4">Услуга «Детектор аудио» предназначена для определения наличия автоответчиков/голосовых ассистентов и человека</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3">
                        <div className="softwere-about-box text-center p-3">
                            <div className="softwere-about-icon">
                                <i className="pe-7s-light"></i>
                            </div>
                            <h4 className="f-15 text-uppercase letter-spacing_2 mt-4">Алгоритм</h4>
                            <p className="mt-4">Использованы новейшие алгоритмы обработки аудиосигнала</p>
                        </div>
                    </div>

                    <div className="col-lg-3">
                        <div className="softwere-about-box text-center p-3">
                            <div className="softwere-about-icon">
                                <i className="pe-7s-portfolio"></i>
                            </div>
                            <h4 className="f-15 text-uppercase letter-spacing_2 mt-4">Легкость в использовании</h4>
                            <p className=" mt-4">Простота интеграции в телефонию компании</p>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="softwere-about-box text-center p-3">
                            <div className="softwere-about-icon">
                                <i className="pe-7s-like2"></i>
                            </div>
                            <DialogComponent 
                                    textButton={"Надежность*"}
                                    titleDialog={"Свидетельство № 2023612049 Телерос"} 
                                    contentDialog={<img src={documentImage}/>}
                                />
                            <p className="mt-4">На рынке телефонии лучшее решение для определения наличия автоответчиков/голосовых ассистентов и человека, разработанное и зарегистрированное в реестре программ для ЭВМ Российской Федерации</p>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="softwere-about-box text-center p-3">
                            <div className="softwere-about-icon">
                                <i className="pe-7s-news-paper"></i>
                            </div>
                            <h4 className="f-15 text-uppercase letter-spacing_2 mt-4">Актуальность</h4>
                            <p className="mt-4">Постоянная актуализация базы автоответчиков и голосовых ассистентов, а также бесшовные бесплатные обновления для каждого клиента</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default AboutProject;