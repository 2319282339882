import React from 'react';
const Home = () => {
    return (
        <section className="softwere-home" id="home">
            <div className="bg-overlay"></div>
            <div className="home-center">
                <div className="home-desc-center">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="softwere-home-content text-white mt-4">
                                    <h3 className="softwere-home-title text-uppercase line-height_1_4 letter-spacing_4">Детектор аудио – наша собственная запатентованная разработка для определения автоответчиков</h3>
                                    <p className="softwere-home-desc f-15 mt-4 mx-auto "></p>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="softwere-home-img text-center">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="softwere-home-shape-img">
                                <img src="images/home-softwere-shape.png" alt="" className="img-fluid mx-auto d-block"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Home;
