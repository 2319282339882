import React from 'react';
import { WhyWeCard } from  './WhyWeCard.jsx'

const whyWeCardsObj = {
    "note2":{
            classIcon:"pe-7s-note2",
            title:"Отчетность",
            textInfo:"Анализ и статистика",
    },
    "user":{
            classIcon:"pe-7s-user",
            title:"Личный кабинет",
            textInfo:"Детализация и прослушивание",
    },
    "drop":{
            classIcon:"pe-7s-drop",
            title:"Гибкость тарифов",
            textInfo:"Индивидуальный подбор тарифа",
    },
    "plug":{
            classIcon:"pe-7s-plug",
            title:"Масштабирование",
            textInfo:"Расширение соединительных линий под требования компании",
    },
}

const WhyWe = () => {
    return (
        <section className="section software-about" id="whywe">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="text-center">
                        <h2 className="heading-title-title letter-spacing_4 text-uppercase">Почему мы</h2>
                    </div>
                </div>
            </div>
            <div className="mt-4">
                <p className="text-center">Запатентованное и зарегистрированное решение в реестре программ для ЭВМ Российской Федерации</p>
            </div>
            <div className="row mt-5 pt-3">
                {Object.keys(whyWeCardsObj).map(id =>
                    <WhyWeCard classIcon={whyWeCardsObj[id].classIcon}
                    title={whyWeCardsObj[id].title} 
                    textInfo={whyWeCardsObj[id].textInfo}
                    key={id}/>
                )}
            </div>
            <div className="mt-4">
                <p className="text-center">Наша миссия - вывести коммуникации b2p на новый уровень. Мы создаем инструменты для адаптации к стремительно меняющимся условиям рынка коммуникаций</p>
            </div>
        </div>
    </section>
    );
}

export default WhyWe;