import React from 'react';

const Pricing = () => {
    return (
        <section className="section" id="pricing">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="text-center">
                        <h2 className="heading-title-title letter-spacing_4 text-uppercase">Наши цифры</h2>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid cta mt-4">
            <div className="pricing-bg-overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="text-center">
                            <div className="counter-icon">
                                <i className="pe-7s-clock"></i>
                            </div>
                            <h2 className="counter-count mt-4 text-white">
                                <span className="counter_value display-5" id="count1">от 1.1 секунды</span>
                            </h2>
                            <h5 className="mt-3 text-uppercase f-16 text-light letter-spacing_2">Время детекции автоответчика</h5>
                        </div>
                    </div>
    
                    <div className="col-lg-4">
                        <div className="text-center">
                            <div className="counter-icon">
                                <i className="pe-7s-call"></i>
                            </div>
                            <h2 className="counter-count mt-4 text-white">
                                <span className="counter_value display-5" id="count2" >16 млн</span>
                            </h2>
                            <h5 className="mt-3 text-uppercase f-16 text-light letter-spacing_2">Звонков в день</h5>
                        </div>
                    </div>
    
                    <div className="col-lg-4">
                        <div className="text-center">
                            <div className="counter-icon">
                                <i className="pe-7s-users"></i>
                            </div>
                            <h2 className="counter-count mt-4 text-white">
                                <span className="counter_value display-5" id="count3" >Более 100</span>
                            </h2>
                            <h5 className="mt-3 text-uppercase f-16 text-light letter-spacing_2">Клиентов детектора</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section>
    );
}

export default Pricing;