import React from 'react';

const Contact = () => {
    return (
        <section className="section agency-contact" id="contact">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col">
                    <div className="text-center">
                        <h2 className="heading-title-title letter-spacing_4 text-uppercase">Наши Контакты</h2>
                    </div>
                </div>
            </div>
            <div className="row justify-content-around mt-4 h-350 align-items-center contact-bg">
                <div className="col-auto">
                    <div className="container">
                        <div className="row">
                            <h5>Информация:</h5>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="mt-3">
                                    <div className="agency-contact-icon float-start">
                                        <i className="mdi mdi-email"></i>
                                    </div>
                                    <div className="agency-contact-info ps-4">
                                        <p className="f-18">Email:<a href="mailto:info@zlb.su">info@zlb.su</a></p>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="agency-contact-icon float-start">
                                        <i className="mdi mdi-phone"></i>
                                    </div>
                                    <div className="agency-contact-info ps-4">
                                        <p className="f-18">Телефон: +7 (499) 681-01-00</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="container">
                        <div className="row">
                                <h5>Поддержка:</h5>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="mt-3">
                                    <div className="agency-contact-icon float-start">
                                        <i className="mdi mdi-email"></i>
                                    </div>
                                    <div className="agency-contact-info ps-4">
                                        <p className="f-18">Email:<a href="mailto:help@zlb.su">help@zlb.su</a></p>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="agency-contact-icon float-start">
                                        <i className="mdi mdi-phone"></i>
                                    </div>
                                    <div className="agency-contact-info ps-4">
                                        <p className="f-18">Телефон: +7 (499) 681-01-86</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                                
            </div>
        </div>
    </section>
    )
}

export default Contact;